import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { PageProps, graphql } from 'gatsby'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { Contact } from 'components/organisms/Contact'

import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { Sales } from 'components/organisms/Sales'
import { Container } from 'components/atoms/Container'
import { ImageType } from 'types/image'

const BreadcrumbsWraper = styled(Container)`
  max-width: calc(1136px + 40px);

  margin-top: 110px;

  ${media.sm.max} {
    margin-top: 68px;
    padding: 0px;
  }
`

export type SalesDataType = {
  heading: string
  content: string
  tiles: Array<{
    image: ImageType
    saleTitle: string
    saleRedirect: string
  }>
}

const promocje: React.FC<PageProps<Queries.PromotionsPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.promotions
  const SINGLE_PAGE = data?.allWpPromotionsSingle
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !SINGLE_PAGE || !PAGE_SEO)
    throw new Error("CMS data didn't load properly")

  const SalesData = {
    heading: PAGE.promotionsHeroTitle!,
    content: PAGE.promotionsHeroDescription!,
    tiles: SINGLE_PAGE.nodes.map((item) => ({
      saleTitle: item?.title!,
      saleRedirect: `/oferty-specjalne/${item.slug}`,
      image: {
        src: item?.promotionSingle?.promotionSingleHeroImg?.localFile
          ?.childImageSharp?.gatsbyImageData!,
        alt: item?.promotionSingle?.promotionSingleHeroImg?.altText!,
      }!,
    }))!,
  }

  const ContactData = {
    heading: PAGE.promotionsFormTitle!,
    formTitle: PAGE.promotionsFormFormTitle!,
    image: {
      src: PAGE.promotionsFormImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE.promotionsFormImg?.altText!,
    }!,
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <BreadcrumbsWraper margin={[0, 'auto']} padding={[0, 20]}>
        <Breadcrumbs crumbs={[{ name: 'Oferty specjalne' }]} />
      </BreadcrumbsWraper>

      <Sales
        heading={SalesData.heading}
        content={SalesData.content}
        tiles={SalesData.tiles}
      />
      <Contact
        heading={ContactData.heading}
        formTitle={ContactData.formTitle}
        image={ContactData.image}
      />
    </Layout>
  )
}

export const query = graphql`
  query PromotionsPage {
    wpPage(slug: { regex: "/promocje/" }) {
      promotions {
        promotionsHeroTitle
        promotionsHeroDescription
        promotionsFormTitle
        promotionsFormFormTitle
        promotionsFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
    allWpPromotionsSingle(limit: 6) {
      nodes {
        slug
        title
        promotionSingle {
          promotionSingleHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  }
`

export default promocje
