import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { H1Light, TextBody1 } from 'components/atoms/Typography'
import SalesCard from 'components/modules/SalesCard/SalesCard'
import { ImageType } from 'types/image'

const SalesContainer = styled(Container)`
  max-width: calc(1136px + 100px);

  display: flex;
  flex-direction: column;
  gap: 80px;

  ${media.lg.max} {
    padding-bottom: 48px;
  }
  ${media.sm.max} {
    padding: 48px 24px;
    gap: 48px;
  }
`

const TextContainer = styled.div`
  width: 100%;
  max-width: 694px;

  margin: 0px auto;

  display: flex;
  flex-direction: column;
  gap: 18px;
`

const TilesContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  column-gap: 40px;

  ${media.xl.max} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.md.max} {
    grid-template-columns: repeat(1, 1fr);
  }
`

type TileType = {
  saleTitle: string
  saleRedirect: string
  image: ImageType
}

type SalesProps = {
  heading: string
  content: string
  tiles: TileType[]
}

export const Sales: React.FC<SalesProps> = ({ heading, content, tiles }) => {
  return (
    <SalesContainer padding={[72, 30, 0]} margin={[0, 'auto']}>
      <TextContainer>
        <H1Light
          as="h1"
          align="center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <TextBody1
          align="center"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </TextContainer>
      <TilesContainer>
        {tiles.map((item) => (
          <SalesCard tileData={item} />
        ))}
      </TilesContainer>
    </SalesContainer>
  )
}
